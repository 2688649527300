import rg4js from 'raygun4js';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, take, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { BRANCH_STORAGE_KEY } from '../branches/branches.constants';
import { WebViewService } from '../app-webview.service';
import { IBranch } from '../branches/models/branch.model';
import { getBrowserFingerprint } from '../utilities/browser';

interface IAuthToken {
  email: string;
  roles: string[];
  staffAuthorityLevel?: AuthorityLevel;
  sub: string;
}

interface ITokenResponse {
  authToken: string;
  refreshToken?: string;
  unlockToken?: string;
}

export interface IRecentUser {
  id: string;
  name: string;
  lastAccessedAt: number;
  unlockToken: string;
}

export enum AuthorityLevel {
  Default = 0,
  Manager = 80,
  Administrator = 100
}

export interface IUser {
  id: string;
  authorityLevel?: AuthorityLevel;
  roles: string[];
  username: string;
}

const LS_AUTH_TOKEN = 'eSalon.authToken';
const LS_BRANCH = 'eSalon.branch';
const LS_RECENT_USERS = 'eSalon.recentUsers';
const LS_REFRESH_TOKEN = 'eSalon.refreshToken';
const LS_USER = 'eSalon.user';

export const ROLE_ADMIN = 'eSMS Admins';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private readonly _user$: BehaviorSubject<IUser | null> = new BehaviorSubject(null);

  public readonly lock$ = new Subject<void>();
  public readonly switchAccount$ = new Subject<void>();
  public readonly user$ = this._user$.asObservable();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _logger: NGXLogger,
    private readonly _router: Router,
    private readonly _webview: WebViewService
  ) {
    this._bindUser();
    this._bindWebview();
  }

  private _bindUser() {
    this._user$.subscribe(user => {
      if (user) {
        rg4js('setUser', {
          identifier: user.username,
          isAnonymous: false
        });
      } else {
        rg4js('setUser', { identifier: '', isAnonymous: true });
      }
    });
  }

  private _bindWebview() {
    this._webview.message$
      .pipe(
        filter(message => message.action.startsWith('auth:'))
      ).subscribe(message => {
        this._logger.debug('[AuthService].[webview] message', message);

        switch (message.action) {
          case 'auth:set-token': {
            const data: ITokenResponse = JSON.parse(message.data);
            this.setDesktopToken(data.authToken, data.refreshToken);
            break;
          }

          default:
            this._logger.warn('[AuthService].[webview] message', message);
            break;
        }
      });
  }

  private _completeLogin(token: ITokenResponse) {
    const decodedToken = this._decodeAuthToken(token.authToken);

    const user: IUser = {
      id: decodedToken.sub,
      authorityLevel: decodedToken.staffAuthorityLevel,
      roles: decodedToken.roles,
      username: decodedToken.email
    };

    localStorage.setItem(LS_USER, JSON.stringify(user));
    this._user$.next(user);

    // Do this after as it will update the authority levels on the user
    this._storeTokenResponse(token);
  }

  private _completeLogout() {
    this._logger.trace('AuthService | completeLogout');

    this._clearRecentUser();

    this._user$.next(null);

    rg4js('endSession');
    localStorage.removeItem(LS_BRANCH);
    localStorage.removeItem(LS_AUTH_TOKEN);
    localStorage.removeItem(LS_REFRESH_TOKEN);
    localStorage.removeItem(LS_USER);
  }

  private _clearRecentUser() {
    const recentUsers = JSON.parse(localStorage.getItem(LS_RECENT_USERS) ?? '[]') as IRecentUser[];
    const user = this._user$.value;
    const existingUser = recentUsers.find(u => u.id === user.id);

    if (existingUser) {
      recentUsers.splice(recentUsers.indexOf(existingUser), 1);
      localStorage.setItem(LS_RECENT_USERS, JSON.stringify(recentUsers));
    }
  }

  private _decodeAuthToken(authToken: string): IAuthToken {
    const decodedToken: any = jwt_decode(authToken);

    return {
      email: decodedToken.email,
      roles: decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] ?? [],
      staffAuthorityLevel: parseInt(decodedToken.StaffAuthorityLevel, 10),
      sub: decodedToken.sub
    }
  }

  private _getBranchFromStorage(): IBranch | null {
    const storedBranch = window.localStorage.getItem(BRANCH_STORAGE_KEY);
    if (!storedBranch) { return null; }
    return JSON.parse(storedBranch);
  }

  private _getRefreshToken(): string {
    return localStorage.getItem(LS_REFRESH_TOKEN) ?? '';
  }

  private _getUserFromStorage(): Observable<IUser> {
    const user = JSON.parse(localStorage.getItem(LS_USER));
    this._logger.debug('[AuthService].[getUserFromStorage] user:', user);

    if (!user) { return of(null); }

    const rawToken = this.getAuthToken();
    if (!rawToken) { return of(null); }

    const authToken = this._decodeAuthToken(rawToken);
    user.authorityLevel = authToken.staffAuthorityLevel;

    return of(user);
  }

  private _storeTokenResponse(token: ITokenResponse) {
    this._logger.trace('[AuthService].[setTokenResponse] token:', token);

    const decodedToken = this._decodeAuthToken(token.authToken);
    this._logger.debug('[AuthService].[setTokenResponse].[decodedToken]:', decodedToken);

    localStorage.setItem(LS_AUTH_TOKEN, token.authToken);

    if (token.refreshToken) {
      localStorage.setItem(LS_REFRESH_TOKEN, token.refreshToken);
    }

    if (token.unlockToken) {
      this._touchRecentUser(token.unlockToken);
    }

    const user = this._user$.value;
    if (!user) { return; }

    user.authorityLevel = decodedToken.staffAuthorityLevel;

    this._user$.next(user);
  }

  private _touchRecentUser(unlockToken: string) {
    const recentUsers = JSON.parse(localStorage.getItem(LS_RECENT_USERS) ?? '[]') as IRecentUser[];
    const user = this._user$.value;

    const existingUser = recentUsers.find(u => u.id === user.id);
    if (existingUser) {
      existingUser.lastAccessedAt = new Date().getTime();
      existingUser.unlockToken = unlockToken;
    } else {
      recentUsers.push({
        id: user.id,
        name: user.username,
        lastAccessedAt: new Date().getTime(),
        unlockToken: unlockToken
      });
    }

    localStorage.setItem(LS_RECENT_USERS, JSON.stringify(recentUsers));
  }

  confirmAccount(userId: string, token: string, password: string, passwordConfirm: string): Observable<void> {
    return this._httpClient.post<void>('/api/auth/confirm', {
      userId,
      token,
      password,
      passwordConfirm
    });
  }

  forgotPassword(email: string): Observable<void> {
    return this._httpClient.post<void>('/api/auth/forgotpassword', { email });
  }

  getAuthToken(): string {
    return localStorage.getItem(LS_AUTH_TOKEN) ?? '';
  }

  getBranchToken(branch: IBranch): Observable<void> {
    this._logger.trace('[AuthService].[getBranchToken]');

    return this._httpClient.post<ITokenResponse>('/api/auth/branch', {
      subscriptionId: branch.subscriptionId,
      branchRef: branch.id
    }).pipe(
      tap(token => {
        this._storeTokenResponse(token);
      }),
      map(() => { })
    );
  }

  getRecentUsers(): IRecentUser[] {
    return JSON.parse(localStorage.getItem(LS_RECENT_USERS) ?? '[]');
  }

  getUser(): Observable<IUser | null> {
    const user = concat(
      this._user$.pipe(take(1), filter(u => !!u)),
      this._getUserFromStorage().pipe(filter(u => !!u), tap(u => this._user$.next(u))),
      this._user$.asObservable()
    );
    return user;
  }

  hasAuthority(level: AuthorityLevel): boolean {
    const authToken = this.getAuthToken();
    const decodedToken = this._decodeAuthToken(authToken);
    const hasAuthority = decodedToken.staffAuthorityLevel >= level;
    return hasAuthority;
  }

  hasRole(role: string): boolean {
    return this._user$.value?.roles?.includes(role) ?? false;
  }

  isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(u => !!u));
  }

  lock() {
    this._logger.debug('[AuthService].[lock]');

    localStorage.removeItem(LS_AUTH_TOKEN);
    localStorage.removeItem(LS_REFRESH_TOKEN);
  }

  login(email: string, password: string, remember: boolean, redirect: boolean = true): Observable<boolean> {
    return this._httpClient.post<ITokenResponse>('/api/auth/login', {
      browserFingerprint: getBrowserFingerprint(),
      email,
      password,
      remember
    }).pipe(
        tap(response => {
          this._completeLogin(response);

          if (redirect) {
            const returnUrl = this._router.routerState.snapshot.root.queryParams.returnUrl;

            if (returnUrl) {
              this._router.navigateByUrl(returnUrl);
            } else {
              this._router.navigateByUrl('/');
            }
          }
        }),
        mapTo(true)
      );
  }

  logout(): Observable<void> {
    this._logger.trace('[AuthService].[logout]');

    return this._httpClient.post<void>('/api/auth/logout', { refreshToken: this._getRefreshToken() })
      .pipe(
        catchError(() => of(null)),
        finalize(() => {
          this._logger.debug('[AuthService].[logout] finalize');
          this._completeLogout();
          this._router.navigateByUrl('/auth/login');
          return of(null);
        })
      );
  }

  refreshAuthToken(): Observable<ITokenResponse> {
    this._logger.trace('[AuthService].[refreshAuthToken]');

    const branch = this._getBranchFromStorage();

    return this._httpClient.post<ITokenResponse>('/api/auth/refresh', {
      branchRef: branch?.id,
      browserFingerprint: getBrowserFingerprint(),
      subscriptionId: branch?.subscriptionId,
      refreshToken: this._getRefreshToken(),
    })
      .pipe(
        tap(tokens => {
          this._storeTokenResponse(tokens);
        })
      );
  }

  resetPassword(email: string, password: string, passwordConfirmation: string, token: string): Observable<void> {
    return this._httpClient.post<void>('/api/auth/resetpassword', {
      email,
      password,
      passwordConfirmation,
      token
    });
  }

  setDesktopToken(authToken: string, refreshToken?: string) {
    this._logger.trace('[AuthService].[setDesktopToken] authToken:', authToken);
    this._logger.trace('[AuthService].[setDesktopToken] refreshToken:', refreshToken);

    localStorage.setItem(LS_AUTH_TOKEN, authToken);

    if (refreshToken) {
      localStorage.setItem(LS_REFRESH_TOKEN, refreshToken);
    }

    this._completeLogin({ authToken, refreshToken });
  }

  unlock(password: string, unlockToken: string): Observable<boolean> {
    const branch = this._getBranchFromStorage();

    const request = {
      subscriptionId: branch?.subscriptionId,
      branchId: branch?.id,
      browserFingerprint: getBrowserFingerprint(),
      password,
      unlockToken
    };

    return this._httpClient.post<ITokenResponse>('/api/auth/unlock', request)
      .pipe(
        tap(response => {
          this._completeLogin(response);
        }),
        mapTo(true)
      );
  }
}
