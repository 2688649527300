import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

declare global {
  interface Window {
    chrome: any;
  }
}

export interface IWebViewMessage {
  action: string;
  data?: string;
  objectId?: number;
}

@Injectable({ providedIn: 'root' })
export class WebViewService {

  private readonly _message$ = new Subject<IWebViewMessage>();

  public readonly message$ = this._message$.asObservable();

  constructor(
    private readonly _logger: NGXLogger,
    private readonly _router: Router
  ) {
    this._bindListener();
  }

  private _bindListener() {
    window?.chrome?.webview?.addEventListener('message', (data: any) => {
      this._logger.debug('[WebViewService] message', data);

      switch (data.data.action) {
        case 'route:to':
          this._router.navigateByUrl(data.data.data);
          break;

        default:
          this._message$.next(data.data);
          break;
      }

    });
  }

  public postMessage(message: IWebViewMessage) {
    window?.chrome?.webview?.postMessage(message);
  }

}
